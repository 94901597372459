import React,{Component} from "react";
import Slider from "react-slick";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ButtonBase from "@material-ui/core/ButtonBase";

import './carousel.css';

class Carousel extends Component{
  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <ButtonBase
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        >
          <ArrowLeft style={{ fontSize: 35 }} />
        </ButtonBase>
        <ButtonBase
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        >
          <ArrowRight style={{ fontSize: 35 }} />
        </ButtonBase>
      </div>
    );
  };
  render(){
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        }
      }
    ]
    };
    return(
        <div className="col-md-12 px-0 px-md-4" >
          <div className="titleCategory clearfix mt-5 mb-2">
            <h4 className="float-left px-3 px-md-0">{this.props.category}</h4>
            <div className="float-right px-3 px-md-0">{this.renderArrows()}</div>
          </div>
            <Slider {...settings} ref={c=>(this.slider=c)}>
                {this.props.carousel}
             </Slider>
        </div>
    )
  }
}

export default Carousel;