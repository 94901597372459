import React,{Component} from 'react';
import { Player } from 'video-react';
import './videos.css'
import Carousel from '../../Component/Carousel';

import image1 from '../../Asset/Images/Paving-A-Pathway.jpg'
import image2 from '../../Asset/Images/How-To-Stay-All-Day-Long.jpg'
import image3 from '../../Asset/Images/Government-Grants.jpg'
import image4 from '../../Asset/Images/The-Importance-Of-Compliance.jpg'
import image5 from '../../Asset/Images/Digital-Leadership.jpg'
import image6 from '../../Asset/Images/BusinessEfficiency_.jpg'

class Video extends Component {
    handleVideo = (id) => {
        this.props.history.push(`/video/${id}`)
      }
    render(){
        const newRelated= [{"id":1,"img":image1,"title":"Paving A Pathway"}, {"id":2,"img":image2,"title":"How To Stay All Day Long"}, {"id":3,"img":image3,"title":"Government Grants"}, {"id":4,"img":image4,"title":"The Importance Of Compliance"}, {"id":5,"img":image5,"title":"Digital Leadership"}, {"id":6,"img":image6,"title":"Business Efficiency"}];
        const listRelated= newRelated.map((dataRelated) =>
          <div className="col">
            <div className="card" onClick={()=> this.handleVideo(dataRelated.id)}>
              <img src={dataRelated.img} class="card-img-top" alt="slider" />
              <div class="card-body">
                <p className="card-text">{dataRelated.title}</p>
              </div>
            </div>
          </div>
        );
        let id = this.props.match.params.id
        return(
            <div className="container">
                <div className="titleVideo col-12 mt-5">
                <h3 className="mb-4">
                    { id === '1' &&
                        'Paving A Pathway'
                    }
                    { id === '2' &&
                        'How To Stay All Day Long'
                    }
                    { id === '3' &&
                        'Government Grants'
                    }
                    { id === '4' &&
                        'The Importance Of Compliance'
                    }
                    { id === '5' &&
                        'Digital Leadership'
                    }
                    { id === '6' &&
                        'Business Efficiency'
                    }
                    { id === '7' &&
                        'Business Efficiency'
                    }
                    { id === '8' &&
                        'Business Efficiency'
                    }
                    { id === '9' &&
                        'Chemical Productivity'
                    }
                    { id === '10' &&
                        'Digital STARS'
                    }
                    { id === '11' &&
                        'Public Services'
                    }
                    { id === '12' &&
                        'Productivity in Developing Economies'
                    }
                    { id === '13' &&
                        'Government Grants'
                    }
                    { id === '14' &&
                        'Pertandingan Video Pendek'
                    }
                    { id === '15' &&
                        'Sustainable Ayam Kampung Farming'
                    }
                    { id === '16' &&
                        'Internalizing The Values of Business Excellence'
                    }
                </h3>
                    { id === '1' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                    { id === '2' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover02.jpg"
                        src="http://tv.jaasys.com/media/competitiveness6.mp4"
                        />
                    }
                    { id === '3' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover03.jpg"
                        src="http://tv.jaasys.com/media/MPCpromo.mp4"
                        />
                    }
                    { id === '4' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                    { id === '5' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover02.jpg"
                        src="http://tv.jaasys.com/media/competitiveness6.mp4"
                        />
                    }
                    { id === '6' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover03.jpg"
                        src="http://tv.jaasys.com/media/MPCpromo.mp4"
                        />
                    }
                    { id === '7' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                    { id === '8' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover02.jpg"
                        src="http://tv.jaasys.com/media/competitiveness6.mp4"
                        />
                    }
                    { id === '9' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover03.jpg"
                        src="http://tv.jaasys.com/media/MPCpromo.mp4"
                        />
                    }
                    { id === '10' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                    { id === '11' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover02.jpg"
                        src="http://tv.jaasys.com/media/competitiveness6.mp4"
                        />
                    }
                    { id === '12' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover03.jpg"
                        src="http://tv.jaasys.com/media/MPCpromo.mp4"
                        />
                    }
                    { id === '13' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                    { id === '14' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover02.jpg"
                        src="http://tv.jaasys.com/media/competitiveness6.mp4"
                        />
                    }
                    { id === '15' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover03.jpg"
                        src="http://tv.jaasys.com/media/MPCpromo.mp4"
                        />
                    }
                    { id === '16' &&
                        <Player
                        playsInline
                        poster="http://tv.jaasys.com/media/cover01.jpg"
                        src="http://tv.jaasys.com/media/competitiveness4.mp4"
                        />
                    }
                <div className="videoDesc">
                    <h5 className="mt-4">Description:</h5>
                    <p>Testing</p>
                </div>
                </div>

                <Carousel carousel={listRelated} category="More Videos" />
            </div>
        )
    }
}

export default Video;