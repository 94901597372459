import Header from './Component/Header';
import Home from './Page/Home';
import Live from './Page/Live';
import Video from './Page/Video';
import Footer from './Component/Footer';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="container-fuild">
        <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/live" component={Live} />
            <Route path="/video/:id" component={Video} />
          </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;