import './footer.css';
import LogoFooter from '../../Asset/Images/mpc-header-512x42.png';

const Footer = () => {
    return(
        <div className="footer mt-5 py-5 text-center">
            <div className="container">
                <div className="footerLogo">
                    <img src={LogoFooter} alt="logoFooter" />
                </div>
                <div className="row mt-5 px-0 px-md-5">
                    <div className="col-6 col-md-3">
                        <ul className="list-group">
                            <li className="list-group-item font-weight-bold">MORE MPC</li>
                            <li className="list-group-item">Website</li>
                            <li className="list-group-item">Contact Us</li>
                            <li className="list-group-item">About Us</li>
                            <li className="list-group-item">Feedback</li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3">
                        <ul className="list-group">
                            <li className="list-group-item font-weight-bold">SUPPORT</li>
                            <li className="list-group-item">Cookie Policy</li>
                            <li className="list-group-item">Privacy Policy</li>
                            <li className="list-group-item">Terms of Us</li>
                            <li className="list-group-item">Help</li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3 mt-md-0 mt-3">
                        <ul className="list-group">
                            <li className="list-group-item font-weight-bold">FRIENDS</li>
                            <li className="list-group-item">Partners</li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3 mt-md-0 mt-3">
                        <ul className="list-group">
                            <li className="list-group-item font-weight-bold">GET YOUR APP</li>
                            <li className="list-group-item">Android</li>
                            <li className="list-group-item">IOS</li>
                        </ul>
                    </div>
                </div>
                <p className="mt-5 copyright">© Copyright 2020 MPC. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer;