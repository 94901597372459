import React,{Component} from 'react';
import './live.css';

import Carousel from '../../Component/Carousel';

import image1 from '../../Asset/Images/Paving-A-Pathway.jpg'
import image2 from '../../Asset/Images/How-To-Stay-All-Day-Long.jpg'
import image3 from '../../Asset/Images/Government-Grants.jpg'
import image4 from '../../Asset/Images/The-Importance-Of-Compliance.jpg'
import image5 from '../../Asset/Images/Digital-Leadership.jpg'
import image6 from '../../Asset/Images/BusinessEfficiency_.jpg'

class Live extends Component {
    handleVideo = (id) => {
        this.props.history.push(`/video/${id}`)
      }
    render(){
        const newRelated= [{"id":1,"img":image1,"title":"Paving A Pathway"}, {"id":2,"img":image2,"title":"How To Stay All Day Long"}, {"id":3,"img":image3,"title":"Government Grants"}, {"id":4,"img":image4,"title":"The Importance Of Compliance"}, {"id":5,"img":image5,"title":"Digital Leadership"}, {"id":6,"img":image6,"title":"Business Efficiency"}];
        const listRelated= newRelated.map((dataRelated) =>
          <div className="col">
            <div className="card" onClick={()=> this.handleVideo(dataRelated.id)}>
              <img src={dataRelated.img} class="card-img-top" alt="slider" />
              <div class="card-body">
                <p className="card-text">{dataRelated.title}</p>
              </div>
            </div>
          </div>
        );
        let id = this.props.match.params.id
        return(
            <div className="container wrapLive">
                <div className="titleVideo col-12 mt-5">
                    <h3 className="mb-4">Live Streaming</h3>
                </div>
                <iframe src="https://iframe.dacast.com/live/1181043d-0da7-c94f-9977-ce65e88edd2c/0d958fe1-213f-99de-e7a3-8e116e4d9b0e" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen className="playerLive"></iframe>
                <div className="videoDesc text-left">
                    <h5 className="mt-4">Description:</h5>
                    <p>Testing</p>
                </div>
                    <Carousel carousel={listRelated} category="More Videos" />
            </div>
        )
    }
}

export default Live;