import './slider.css';
import imgSlider from './../../Asset/Images/Slider/slider.jpg'

const Slider = () => {
    return(
        <div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src={imgSlider} class="d-block w-100" alt="..." />
            </div>
        </div>
        </div>
        </div>
    )
}

export default Slider;