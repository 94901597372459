import React,{Component} from 'react';
import './header.css';
import Logo from '../../Asset/Images/logo-mpc.png';
import { Link } from 'react-router-dom';

class Header extends Component {
    render(){
        return(
            <nav className="navbar navbar-expand-lg navbar-light shadow">
                <div className="navbar-brand"><Link to="/" className="nav-link"><img src={Logo} alt="Logo"/></Link></div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav mr-auto px-3 px-md-5">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">HOME</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/live" className="nav-link">LIVE</Link>
                        </li>
                        <li class="nav-item">
                            <div className="nav-link">VIDEO</div>
                        </li>
                        <li class="nav-item">
                            <div className="nav-link">CATCH UP</div>
                        </li>
                    </ul>
                    <div className="mt-3 mt-md-0">
                    <i class="fas fa-lg fa-search mx-3"></i>
                    <i class="fab fa-lg fa-facebook-f mx-3"></i>
                    <i class="fab fa-lg fa-twitter mx-2"></i>
                    <i class="fab fa-lg fa-instagram mx-2"></i>
                    <i class="fab fa-lg fa-youtube mx-2"></i>
                    </div>
                </div>
            </nav>
        )
    }

}

export default Header;