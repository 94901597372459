import React, {Component} from "react";
import './home.scss';
import MySlider from '../../Component/Slider';
import Carousel from '../../Component/Carousel';

import image1 from '../../Asset/Images/Paving-A-Pathway.jpg'
import image2 from '../../Asset/Images/How-To-Stay-All-Day-Long.jpg'
import image3 from '../../Asset/Images/Government-Grants.jpg'
import image4 from '../../Asset/Images/The-Importance-Of-Compliance.jpg'
import image5 from '../../Asset/Images/Digital-Leadership.jpg'
import image6 from '../../Asset/Images/BusinessEfficiency_.jpg'
import image7 from '../../Asset/Images/ChemicalProductivity_.jpg'
import image8 from '../../Asset/Images/DigitalSTARS_.jpg'
import image9 from '../../Asset/Images/PublicServices.jpg'
import image10 from '../../Asset/Images/ProductivityinDevelopingEconomies.jpg'
import image11 from '../../Asset/Images/PertandinganVideoPendek_.jpg'
import image12 from '../../Asset/Images/SustainableAyamKapungFarming_.jpg'
import image13 from '../../Asset/Images/Internalizing.jpg'

class Home extends Component {

  handleVideo = (id) => {
    this.props.history.push(`/video/${id}`)
  }

    render() {
      const newRelease= [{"id":1,"img":image1,"title":"Paving A Pathway"}, {"id":2,"img":image2,"title":"How To Stay All Day Long"}, {"id":3,"img":image3,"title":"Government Grants"}, {"id":4,"img":image4,"title":"The Importance Of Compliance"}, {"id":5,"img":image5,"title":"Digital Leadership"}, {"id":6,"img":image6,"title":"Business Efficiency"}];
      const listnewRelease = newRelease.map((dataNewRelease) =>
        <div className="col">
          <div className="card" onClick={()=> this.handleVideo(dataNewRelease.id)}>
            <img src={dataNewRelease.img} class="card-img-top" alt="slider" />
            <div class="card-body">
              <p className="card-text">{dataNewRelease.title}</p>
            </div>
          </div>
        </div>
      );
      const Compertitive= [{"id":7,"img":image6,"title":"Business Efficiency"}];
      const listCompertitive = Compertitive.map((dataCompertitive) =>
        <div className="col">
          <div className="card" onClick={()=> this.handleVideo(dataCompertitive.id)}>
            <img src={dataCompertitive.img} class="card-img-top" alt="slider" />
            <div class="card-body">
              <p className="card-text">{dataCompertitive.title}</p>
            </div>
          </div>
        </div>
      );
      const Popular = [{"id":8,"img":image6,"title":"Business Efficiency"}, {"id":9,"img":image7,"title":"Chemical Productivity"}, {"id":10,"img":image8,"title":"Digital STARS"}, {"id":11,"img":image9,"title":"Public Services"}, {"id":12,"img":image10,"title":"Productivity in Developing Economies"}, {"id":13,"img":image3,"title":"Government Grants"}];
      const listPopular = Popular.map((dataPopular) =>
        <div className="col">
          <div className="card" onClick={()=> this.handleVideo(dataPopular.id)}>
            <img src={dataPopular.img} class="card-img-top" alt="slider" />
            <div class="card-body">
              <p className="card-text">{dataPopular.title}</p>
            </div>
          </div>
        </div>
      );
      const Webinar = [{"id":14,"img":image11,"title":"Pertandingan Video Pendek"}, {"id":15,"img":image12,"title":"Sustainable Ayam Kampung Farming"}, {"id":16,"img":image13,"title":"Internalizing The Values of Business Excellence"}];
      const listWebinar = Webinar.map((dataWebinar) =>
        <div className="col">
          <div className="card" onClick={()=> this.handleVideo(dataWebinar.id)}>
            <img src={dataWebinar.img} class="card-img-top" alt="slider" />
            <div class="card-body">
              <p className="card-text">{dataWebinar.title}</p>
            </div>
          </div>
        </div>
      );
        return(
          <div className="home">
            <MySlider />
            <div>
            <Carousel carousel={listnewRelease} category="New Releases" />
            </div>
            <div>
            <Carousel carousel={listCompertitive} category="Competitiveness Videos" />
            </div>
            <div>
            <Carousel carousel={listPopular} category="Popular On MPC" />
            </div>
            <div>
            <Carousel carousel={listWebinar} category="Productivity Webinar Series"/>
            </div>
          </div>
        );
    }
}

export default Home;